
@font-face {
  font-family: Champion;
  src: url(./fonts/Champion-HTF-Welterweight.otf) format("opentype");
  font-display: swap
}

@font-face {
  font-family: Avenir Regular;
  src: url(./fonts/Avenir-Regular.ttf) format("truetype");
  font-display: swap
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  color: #13294B;
  font-family: 'Champion', Verdana;
  font-size: 38px;
  font-style: normal;
  font-weight: 500; /* 500 */
  line-height: 44px; /* 44px */
  margin: 0;
}

@media screen and (min-width: 800px) {
  h2 {
    font-size: 48px; /* 48px */
    line-height: 60px; /* 60px */
  }
}


h3 {
  color: #8D9092;
  font-family: 'Champion', Verdana;
  font-size: 22px; /* 22px */
  font-style: normal;
  font-weight: 500; /* 500 */
  line-height: 30px; /* 30px */
  margin: 8px 0 0 0;
}

@media screen and (min-width: 800px) {
  h3 {
    font-size: 32px;
    line-height: 38px; /* 44px */
  }
}


h4 {
  color: #2C2A29;
  font-family: 'Avenir Regular', Verdana;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 32px 0 16px 0;
 }

@media screen and (min-width: 800px) {
  input {
    font-size: 24px;
    line-height: 33px;
  }
}


input {
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #8D9092;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Avenir Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  height: 56px;
  margin-bottom: 20px;
  padding: 0 18px;
  width: 100%;
}

input::placeholder {
  color: #A2AAAD;
}

